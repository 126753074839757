import React, { useState } from "react";

function Room (props) {
    const [getRoominputvalue, setRoominputvalue] = useState("");


    const SendRoomInfo = (e) => {
        e.preventDefault();
        props.Room(getRoominputvalue);
        console.log(getRoominputvalue);
    }


    const InputHandler = (e) => {
        setRoominputvalue(e.target.value);
      }

    return(
        <div className="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
       <div className='col-md-7'>
       <h2>Enter a Room ID to Join or Create a Room</h2>
       <form onSubmit={SendRoomInfo}>
       <input type='text' className='form-control' value={getRoominputvalue} onChange={InputHandler}></input>
       <button type='submit' className='btn btn-primary'>Send</button>
     </form>
       </div>
       </div>
     </div>
    );

}

export default Room;