import './App.css';
import Room from './components/Room';
import 'bootstrap/dist/css/bootstrap.min.css';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import React, { useEffect, useState} from 'react'; 
import GameHolder from './components/GameHolder';

function MSG_History(props) {
  const msgs_list = props.msgs;
  const listItems = msgs_list.map((msg) =>    <li>{msg}</li>  );  return (
    <ul>{listItems}</ul>  );
}




function App() {
  //Vars and States
  const [getinputvalue, setinputvalue] = useState("");
  const [getLastMsg, setLastMsg] = useState("");
  const [getUserID, setUserID] = useState("");
  const [getMsgArray, setMsgArray] = useState([]);
  const socketUrl = 'ws://qwixxonline.com:8080';
  const [getMsgHistory, setMsgHistory] = useState([]);
  const [getRoomID, setRoomID] = useState("none");
  const [getDevMode, setDevMode] = useState("0");
  const [getDiceRolls, setDiceRolls] = useState([0,0,0,0,0,0]);

  var Dice_arr = [];

 
  //Setting up websockets
  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  //Check for changes in last message
  useEffect(() => {
    if (typeof lastMessage !== "undefined" && lastMessage !== null) {
        //setMessageHistory((prev) => prev.concat(lastMessage));
        check_msg_for_status(lastMessage.data);
        console.log(lastMessage.data);
        console.log(lastJsonMessage);
      }
    }, [lastMessage]);

  const check_msg_for_status = (msg) => {
    //console.log(msg);
    var msg_array = msg.split(':');
    var user = msg_array[0];
    var func = msg_array[1];
    var action = msg_array[2];
    var request_data = msg_array[3];
    switch (func) {
      case 'setUserID':
        switch (action){
          case 'Give':
            setUserID(request_data);
            console.log("UserName is now: " + request_data);
            break;
        }
        break;
      case 'Room':
          switch (action) {
            case 'Join':
              setRoomID(request_data);
              console.log("Request Granted for Room: " + request_data);
              break;
            case 'Leave':
              setRoomID("none");  
              console.log("Request Granted to Leave Room: " + request_data);
              break;
            case 'RoomFull':
              setRoomID("error");
              console.log("Room Full Try another");
              break;  

          }
      case 'Dice':
          switch(action){
            case 'set':
              console.log('Set Dice To'+ request_data);
              Dice_arr = request_data.split('/');
              setDiceRolls(Dice_arr);
              break;
          }
      case 'Ping':
          switch(action){
            case 'Give':
              sendMessage( getUserID + ":" + 'Ping' + ':' + 'Give' + ':' + 'Pong');
          }        
      break;
    }
  }  



  const sendtest = (e) => {
    e.preventDefault();
    sendMessage( 'MSG:' + getUserID + ': ' + getinputvalue);
    //setLastMsg(lastJsonMessage);
    //console.log(lastJsonMessage);
    //console.log(lastMessage);
    //console.log(JSON.parse(lastMessage));
  }
  const JoinRoom = (room_id) => {
    console.log("User is trying to join room: " + room_id);
    sendMessage( getUserID + ":" + 'Room' + ':' + 'Join' + ':' + room_id);
 
 }


const LeaveRoom = () => {
  console.log("Left Room");
  sendMessage( getUserID + ":" + 'Room' + ':' + 'Leave' + ':' + getRoomID);
  setRoomID("none");
}

const RollDice = () => {
  sendMessage( getUserID + ":" + 'Dice' + ':' + 'Roll' + ':' + getRoomID);
}
  
  if (getRoomID === "none") {
    return (
      <div className='Main-Menu'>
      <div className='container'>
        <Room Room={JoinRoom}></Room>
      </div>
      </div>
    );
  } else if (getRoomID === "error") {
    return (
      <div className='Main-Menu'>
      <div className='container'>
        <h2 className='col align-self-center' style={{color: 'red',textAlign: 'center' }}>Error Room Full: Try a Different Room</h2>
        <Room Room={JoinRoom}></Room>
      </div>
      </div>
    );
  } 
  
  
  else {
  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <GameHolder RoomID={getRoomID} leaveRoom={LeaveRoom} DiceRolls={getDiceRolls} RollDice={RollDice}></GameHolder>     
    </div>
  );
  }
}

export default App;
