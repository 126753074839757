import React, { useState, useEffect }  from 'react';
import "./css/ScoreCardContainer.css";


function ScoreCardContainer() {
  const [Get_Red_label_Values, Set_Red_label_Values] = useState({0: '', 1: '2', 2: '3',3: '4',4: '5',5: '6',6: '7',7: '8',8: '9',9: '10',10: '11',11: '12', 12: ''});
  const [Get_Yell_label_Values, Set_Yell_label_Values] = useState({0: '', 1: '2', 2: '3',3: '4',4: '5',5: '6',6: '7',7: '8',8: '9',9: '10',10: '11',11: '12', 12: ''});
  const [Get_Green_label_Values, Set_Green_label_Values] = useState({0: '', 1: '12', 2: '11',3: '10',4: '9',5: '8',6: '7',7: '6',8: '5',9: '4',10: '3',11: '2',12: ''});
  const [Get_Blue_label_Values, Set_Blue_label_Values] = useState({0: '', 1: '12', 2: '11',3: '10',4: '9',5: '8',6: '7',7: '6',8: '5',9: '4',10: '3',11: '2',12: ''});
  const [Get_Chance_Values, Set_Chance_Values] = useState({0: '', 1: '', 2:'', 3: ''})
  const [Get_Red_Score, Set_Red_Score] = useState(0);
  const [Get_Yell_Score, Set_Yell_Score] = useState(0);
  const [Get_Green_Score, Set_Green_Score] = useState(0);
  const [Get_Blue_Score, Set_Blue_Score] = useState(0);
  const [Get_Chance_Score, Set_Chance_Score] = useState(0);
  const [Get_Total_Score, Set_Total_Score] = useState(0);

  const Chance_Handler = (num) => {
    if (Get_Chance_Values[num] == '') {
        Set_Chance_Values({...Get_Chance_Values, [num]: 'X'});
    } else {
        Set_Chance_Values({...Get_Chance_Values, [num]: ''});
    }

  }






  const label_Click = (color,id) => {
      
      switch (color){
      case 'Red':
        var default_nums = ['','2','3','4','5','6','7','8','9','10','11','12','']
        Set_Red_label_Values(set_boxes_for_row(Get_Red_label_Values,id,default_nums));
        break;
      case 'Yell':
        var default_nums = ['','2','3','4','5','6','7','8','9','10','11','12','']
        Set_Yell_label_Values(set_boxes_for_row(Get_Yell_label_Values,id,default_nums));
        break;
      case 'Green':
        var default_nums = ['','12','11','10','9','8','7','6','5','4','3','2','']
        Set_Green_label_Values(set_boxes_for_row(Get_Green_label_Values,id,default_nums));
        break;
      case 'Blue':
        var default_nums = ['','12','11','10','9','8','7','6','5','4','3','2','']
        Set_Blue_label_Values(set_boxes_for_row(Get_Blue_label_Values,id,default_nums));
        break;  

      } 
      Update_Scores_Values();   

  } 

  const set_boxes_for_row = (obj,num,default_nums) => {
    var num_arr = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13'];
    var changed_values = {};
    var highest_x = 0;
    if (obj[num] == 'X'){
      var og_number = default_nums[num]
      changed_values[num] = og_number.toString();
      for (let i = 0, len = 13; i < len; i++) {
        if (obj[i] == 'X' && i > highest_x && i != num) {
          highest_x = i;
          console.log(highest_x);

        } 
      }
      for (let i = 0, len = 13; i < len; i++) {
        if (obj[i] == '#' && i > highest_x) {
            og_number = default_nums[i]
            changed_values[i] = og_number.toString(); 
        } 
      } 
    } else {
      changed_values[num] = 'X';
      for (let i = 0, len = 13; i < len; i++) {
        if (obj[i] in num_arr && i < num) {
          changed_values[i] = '#'; 
        } 
      } 

    }

    return {...obj, ...changed_values}
  }

  const Update_Scores_Values = () => {
    var Red_X_Count = 0;
    var Yell_X_Count = 0;
    var Green_X_Count = 0;
    var Blue_X_Count = 0;
    var Chance_X_Count = 0;
    for (let i = 0, len = 13; i < len; i++) {
        if (Get_Red_label_Values[i] == 'X') {
            Red_X_Count = Red_X_Count + 1;
        } 
        if (Get_Yell_label_Values[i] == 'X') {
            Yell_X_Count = Yell_X_Count + 1;
        }
        if (Get_Green_label_Values[i] == 'X') {
            Green_X_Count = Green_X_Count + 1;
        }
        if (Get_Blue_label_Values[i] == 'X') {
            Blue_X_Count = Blue_X_Count + 1;
        }
     }
     for (let i = 0, len = 4; i < len; i++) {
        if (Get_Chance_Values[i] == 'X') {
            Chance_X_Count = Chance_X_Count + 1;
        }
     }   
     Set_Red_Score(Convert_X_To_Total(Red_X_Count));
     Set_Yell_Score(Convert_X_To_Total(Yell_X_Count));
     Set_Green_Score(Convert_X_To_Total(Green_X_Count));
     Set_Blue_Score(Convert_X_To_Total(Blue_X_Count));
     Set_Chance_Score(Covert_Chances_To_Num(Chance_X_Count));
     Set_Total_Score(Convert_X_To_Total(Red_X_Count) + Convert_X_To_Total(Yell_X_Count) + Convert_X_To_Total(Green_X_Count) + Convert_X_To_Total(Blue_X_Count) - Covert_Chances_To_Num(Chance_X_Count));
    }

  const Covert_Chances_To_Num = (Xcount) => {
    switch (Xcount) {
        case 0:
            return 0;
        case 1: 
            return 5;
        case 2:
            return 10;
        case 3: 
            return 15;
        case 4: 
            return 20;                 
    }
  }


  const Convert_X_To_Total = (Xcount) => {
    switch (Xcount){
        case 0:
            return 0;
        case 1:
            return 1;
        case 2:
            return 3;
        case 3:
            return 6;
        case 4:
            return 10;    
        case 5:
            return 15;
        case 6: 
            return 21; 
        case 7:
            return 28;
        case 8:
            return 36;
        case 9:
            return 45;
        case 10:
            return 55;
        case 11:
            return 66;
        case 12:
            return 78;        
    }
    }

    useEffect(() => {
        Update_Scores_Values();
    }, [Get_Red_label_Values, Get_Blue_label_Values, Get_Green_label_Values, Get_Yell_label_Values, Get_Chance_Values]);


    
  return (
    <div class="board">
        <div class="notice">At least 5 X<i class="fa-solid fa-xmark"></i>'s</div>
        <div class="red color">
          <input id="red-lock" type="checkbox" onClick={e => label_Click('Red',12)}/>
          <label class="lock" for="red-lock">
            <span>{Get_Red_label_Values['12']}</span>
          </label>

          <input id="red-two" type="checkbox" onClick={e => label_Click('Red',11)}/>
          <label class="number" for="red-two">
            <span>{Get_Red_label_Values['11']}</span>
          </label>

          <input id="red-three" type="checkbox" onClick={e => label_Click('Red',10)}/>
          <label class="number" for="red-three">
            <span>{Get_Red_label_Values['10']}</span>
          </label>

          <input id="red-four" type="checkbox" onClick={e => label_Click('Red',9)}/>
          <label class="number" for="red-four">
            <span>{Get_Red_label_Values['9']}</span>
          </label>

          <input id="red-five" type="checkbox" onClick={e => label_Click('Red',8)}/>
          <label class="number" for="red-five">
            <span>{Get_Red_label_Values['8']}</span>
          </label>

          <input id="red-six" type="checkbox" onClick={e => label_Click('Red',7)}/>
          <label class="number" for="red-six">
            <span>{Get_Red_label_Values['7']}</span>
          </label>

          <input id="red-seven" type="checkbox" onClick={e => label_Click('Red',6)}/>
          <label class="number" for="red-seven">
            <span>{Get_Red_label_Values['6']}</span>
          </label>

          <input id="red-eight" type="checkbox" onClick={e => label_Click('Red',5)}/>
          <label class="number" for="red-eight">
            <span>{Get_Red_label_Values['5']}</span>
          </label>

          <input id="red-nine" type="checkbox" onClick={e => label_Click('Red',4)}/>
          <label class="number" for="red-nine">
            <span>{Get_Red_label_Values['4']}</span>
          </label>

          <input id="red-ten" type="checkbox" onClick={e => label_Click('Red',3)}/>
          <label class="number" for="red-ten">
            <span>{Get_Red_label_Values['3']}</span>
          </label>

          <input id="red-eleven" type="checkbox" onClick={e => label_Click('Red',2)}/>
          <label class="number" for="red-eleven">
            <span>{Get_Red_label_Values['2']}</span>
          </label>

          <input id="red-twelve" type="checkbox" onClick={e => label_Click('Red',1)}/>
          <label class="number" for="red-twelve">
            <span>{Get_Red_label_Values['1']}</span>
          </label>
        </div>

        <div class="yellow color">
            <input id="yellow-lock" type="checkbox" onClick={e => label_Click('Yell',12)}/>
            <label class="lock" for="yellow-lock">
                <span>{Get_Yell_label_Values['12']}</span>
            </label>

            <input id="yellow-two" type="checkbox" onClick={e => label_Click('Yell',11)}/>
            <label class="number" for="yellow-two">
                <span>{Get_Yell_label_Values['11']}</span>
            </label>

            <input id="yellow-three" type="checkbox" onClick={e => label_Click('Yell',10)}/>
            <label class="number" for="yellow-three">
                <span>{Get_Yell_label_Values['10']}</span>
            </label>

            <input id="yellow-four" type="checkbox" onClick={e => label_Click('Yell',9)}/>
            <label class="number" for="yellow-four">
                <span>{Get_Yell_label_Values['9']}</span>
            </label>

            <input id="yellow-five" type="checkbox" onClick={e => label_Click('Yell',8)}/>
            <label class="number" for="yellow-five">
                <span>{Get_Yell_label_Values['8']}</span>
            </label>

            <input id="yellow-six" type="checkbox" onClick={e => label_Click('Yell',7)}/>
            <label class="number" for="yellow-six">
                <span>{Get_Yell_label_Values['7']}</span>
            </label>

            <input id="yellow-seven" type="checkbox" onClick={e => label_Click('Yell',6)}/>
            <label class="number" for="yellow-seven">
                <span>{Get_Yell_label_Values['6']}</span>
            </label>

            <input id="yellow-eight" type="checkbox" onClick={e => label_Click('Yell',5)}/>
            <label class="number" for="yellow-eight">
                <span>{Get_Yell_label_Values['5']}</span>
            </label>

            <input id="yellow-nine" type="checkbox" onClick={e => label_Click('Yell',4)}/>
            <label class="number" for="yellow-nine">
                <span>{Get_Yell_label_Values['4']}</span>
            </label>

            <input id="yellow-ten" type="checkbox" onClick={e => label_Click('Yell',3)}/>
            <label class="number" for="yellow-ten">
                <span>{Get_Yell_label_Values['3']}</span>
            </label>

            <input id="yellow-eleven" type="checkbox" onClick={e => label_Click('Yell',2)}/>
            <label class="number" for="yellow-eleven">
                <span>{Get_Yell_label_Values['2']}</span>
            </label>

            <input id="yellow-twelve" type="checkbox" onClick={e => label_Click('Yell',1)}/>
            <label class="number" for="yellow-twelve">
                <span>{Get_Yell_label_Values['1']}</span>
            </label>
        </div>

        <div class="green color">
            <input id="green-lock" type="checkbox" onClick={e => label_Click('Green',12)}/>
            <label class="lock" for="green-lock">
                <span>{Get_Green_label_Values['12']}</span>
            </label>

            <input id="green-two" type="checkbox" onClick={e => label_Click('Green',11)}/>
            <label class="number" for="green-two">
                <span>{Get_Green_label_Values['11']}</span>
            </label>

            <input id="green-three" type="checkbox" onClick={e => label_Click('Green',10)}/>
            <label class="number" for="green-three">
                <span>{Get_Green_label_Values['10']}</span>
            </label>

            <input id="green-four" type="checkbox" onClick={e => label_Click('Green',9)}/>
            <label class="number" for="green-four">
                <span>{Get_Green_label_Values['9']}</span>
            </label>

            <input id="green-five" type="checkbox" onClick={e => label_Click('Green',8)}/>
            <label class="number" for="green-five">
                <span>{Get_Green_label_Values['8']}</span>
            </label>

            <input id="green-six" type="checkbox" onClick={e => label_Click('Green',7)}/>
            <label class="number" for="green-six">
                <span>{Get_Green_label_Values['7']}</span>
            </label>

            <input id="green-seven" type="checkbox" onClick={e => label_Click('Green',6)}/>
            <label class="number" for="green-seven">
                <span>{Get_Green_label_Values['6']}</span>
            </label>

            <input id="green-eight" type="checkbox" onClick={e => label_Click('Green',5)}/>
            <label class="number" for="green-eight">
                <span>{Get_Green_label_Values['5']}</span>
            </label>

            <input id="green-nine" type="checkbox" onClick={e => label_Click('Green',4)}/>
            <label class="number" for="green-nine">
                <span>{Get_Green_label_Values['4']}</span>
            </label>

            <input id="green-ten" type="checkbox" onClick={e => label_Click('Green',3)}/>
            <label class="number" for="green-ten">
                <span>{Get_Green_label_Values['3']}</span>
            </label>

            <input id="green-eleven" type="checkbox" onClick={e => label_Click('Green',2)}/>
            <label class="number" for="green-eleven">
                <span>{Get_Green_label_Values['2']}</span>
            </label>

            <input id="green-twelve" type="checkbox" onClick={e => label_Click('Green',1)}/>
            <label class="number" for="green-twelve">
                <span>{Get_Green_label_Values['1']}</span>
            </label>
        </div>

        <div class="blue color">
            <input id="blue-lock" type="checkbox"  onClick={e => label_Click('Blue',12)}/>
            <label class="lock" for="blue-lock">
                <span>{Get_Blue_label_Values['12']}</span>
            </label>

            <input id="blue-two" type="checkbox" onClick={e => label_Click('Blue',11)}/>
            <label class="number" for="blue-two">
                <span>{Get_Blue_label_Values['11']}</span>
            </label>

            <input id="blue-three" type="checkbox" onClick={e => label_Click('Blue',10)}/>
            <label class="number" for="blue-three">
                <span>{Get_Blue_label_Values['10']}</span>
            </label>

            <input id="blue-four" type="checkbox" onClick={e => label_Click('Blue',9)}/>
            <label class="number" for="blue-four">
                <span>{Get_Blue_label_Values['9']}</span>
            </label>

            <input id="blue-five" type="checkbox" onClick={e => label_Click('Blue',8)}/>
            <label class="number" for="blue-five">
                <span>{Get_Blue_label_Values['8']}</span>
            </label>

            <input id="blue-six" type="checkbox" onClick={e => label_Click('Blue',7)}/>
            <label class="number" for="blue-six">
                <span>{Get_Blue_label_Values['7']}</span>
            </label>

            <input id="blue-seven" type="checkbox" onClick={e => label_Click('Blue',6)}/>
            <label class="number" for="blue-seven">
                <span>{Get_Blue_label_Values['6']}</span>
            </label>

            <input id="blue-eight" type="checkbox" onClick={e => label_Click('Blue',5)}/>
            <label class="number" for="blue-eight">
                <span>{Get_Blue_label_Values['5']}</span>
            </label>

            <input id="blue-nine" type="checkbox" onClick={e => label_Click('Blue',4)}/>
            <label class="number" for="blue-nine">
                <span>{Get_Blue_label_Values['4']}</span>
            </label>

            <input id="blue-ten" type="checkbox" onClick={e => label_Click('Blue',3)}/>
            <label class="number" for="blue-ten">
                <span>{Get_Blue_label_Values['3']}</span>
            </label>

            <input id="blue-eleven" type="checkbox" onClick={e => label_Click('Blue',2)}/>
            <label class="number" for="blue-eleven">
                <span>{Get_Blue_label_Values['2']}</span>
            </label>

            <input id="blue-twelve" type="checkbox" onClick={e => label_Click('Blue',1)}/>
            <label class="number" for="blue-twelve">
                <span>{Get_Blue_label_Values['1']}</span>
            </label>
        </div>
        <div className='info-chance'>
            <p style={{marginBottom: '0px', fontSize: '1.0em'}}>Chances:</p>
        <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onClick={e => Chance_Handler(0)} />
                <label class="form-check-label" for="inlineCheckbox1" >1</label>
            </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"  onClick={e => Chance_Handler(1)}/>
            <label class="form-check-label" for="inlineCheckbox2" >2</label>
            </div>
        <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" onClick={e => Chance_Handler(2)} />
        <label class="form-check-label" for="inlineCheckbox3" >3</label>
        </div>
        <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" onClick={e => Chance_Handler(3)} />
        <label class="form-check-label" for="inlineCheckbox3" >4</label>
        </div>
        </div>
        <div className='scorewrap'>
            <div className='number'>
            <span>Score:</span>
            </div>
            <div className='scoreholderred number'>
            <span>{Get_Red_Score}</span>
            </div>
            <div className='number'>
            <span>+</span>
            </div>
            <div className='scoreholderyellow number'>
            <span>{Get_Yell_Score}</span>
            </div>
            <div className='number'>
            <span>+</span>
            </div>
            <div className='scoreholdergreen number'>
            <span>{Get_Green_Score}</span>
            </div>
            <div className='number'>
            <span>+</span>
            </div>
            <div className='scoreholderblue number'>
            <span>{Get_Blue_Score}</span>
            </div>
            <div className='number'>
            <span>-</span>
            </div>
            <div className=' scoreholderwhite number'>
            <span>{Get_Chance_Score}</span>
            </div>
            <div className='number'>
            <span>=</span>
            </div>
            <div className='scoreholderwhite number'>
            <span>{Get_Total_Score}</span>
            </div>
        </div>
    </div>
  );
}

export default ScoreCardContainer;