import React, { useState } from "react";
import Dice from "./Dice";
import './css/GameHolder.css'
import ScoreCardContainer from "./ScoreCardContainer";


function GameHolder (props) {


    return (
        <div className="container">
            <div><h2>Room ID: {props.RoomID}</h2> <button className="btn btn-primary" onClick={props.leaveRoom}>Leave Room</button></div> 
            <div className="table-row-dice row">
            <div className="col">    
            <td><Dice RollValue={props.DiceRolls[0]} Color={"Dice_Text_Red"} /></td>
            </div>
            <div className="col">
            <td><Dice RollValue={props.DiceRolls[1]} Color={"Dice_Text_Blue"} /></td>
            </div>
            <div className="col">
            <td><Dice RollValue={props.DiceRolls[4]} Color={"Dice_Text_Yellow"} /></td>
            </div>
            <div className="col">
            <td><Dice RollValue={props.DiceRolls[5]} Color={"Dice_Text_Green"} /></td>
            </div>
            <div className="col">
            <td><Dice RollValue={props.DiceRolls[2]} Color={"Dice_Text_Black"} /></td>
            </div>
            <div className="col">
            <td><Dice RollValue={props.DiceRolls[3]} Color={"Dice_Text_Black"}/></td>
            </div>
            </div>
            <div className="row">
             <div className="col col-sm">   
            <button className="Roll_Button btn btn-primary" onClick={props.RollDice}>Roll Dice</button>
            </div>
            </div>
            <ScoreCardContainer></ScoreCardContainer>
        </div>
                

    );
}

export default GameHolder;