import './css/Dice.css'
import React from "react";

function Dice (props) {
    return(
        <div className='Dice__Wrapper col-sm'>
            <div className={props.Color}>
                <h2>{props.RollValue}</h2>
            </div>
        </div>
    );


}

export default Dice;